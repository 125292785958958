export const selectors = {
  likesFilledNumber: '#likesFilledNumber',
  likesDefaultNumber: '#likesDefaultNumber',
  likesDefaultBlack: '#likesDefaultBlack',
  likesFilledBlack: '#likesFilledBlack',
  views: '#viewsNumber',
  viewsContainer: '#viewsContainer',
  comments: '#commentsNumber',
  commentsContainer: '#commentsContainer',
} as const;
